<template>
  <div
    v-click-outside="() => (isFocus = false)"
    class="relative z-[2] flex-1"
    :class="{ 'z-[30]': isFocus }"
  >
    <input-text
      v-model="keyword"
      :inputId="uuid"
      :placeholder="placeholder"
      :disabled="isDisabled"
      searchable
      :searchOnClear="searchOnClear"
      :maxLength="'100'"
      :allowInputMaxLength="false"
      :countable="false"
      @search="onSearch"
      @clear="$emit('clear')"
      @click="$emit('click')"
    />
    <div
      v-if="isFocus && showLayer"
      :class="searchLayerClass"
      class="absolute top-[calc(100%+.4rem)] left-0 right-0 p-16 border-1 border-solid border-border bg-surface-layer rounded-2xl shadow-[0_.4rem_1rem_0_rgba(0,0,0,.10)] z-[25]"
    >
      <div class="flex flex-col gap-8 max-h-[30rem] studio-scrollbar-4 -mr-16">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue';

import InputText from '@/components/validation/input-text.vue';
import { generateUUID } from '@/utils/uuid.util';

withDefaults(
  defineProps<{
    placeholder?: string;
    showLayer?: boolean;
    searchLayerClass?: string;
    searchOnClear?: boolean;
    isDisabled?: boolean;
  }>(),
  { placeholder: '', showLayer: true, searchLayerClass: '', isDisabled: false }
);

const $emit = defineEmits<{
  change: [v: string];
  search: [v: string];
  clear: [];
  click: [];
}>();

const uuid = `search_layer_${generateUUID()}`;

const keyword = ref('');
const isFocus = ref(false);

const handleFocus = () => {
  isFocus.value = true;
};

const onSearch = () => {
  $emit('search', keyword.value);
};

onMounted(() => {
  const searchLayer = document.getElementById(uuid);
  if (!searchLayer) {
    return;
  }
  searchLayer.addEventListener('focus', handleFocus);
});

onBeforeUnmount(() => {
  const searchLayer = document.getElementById(uuid);
  if (!searchLayer) {
    return;
  }
  searchLayer.removeEventListener('focus', handleFocus);
});
</script>
